import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

// plugins
import swal from 'sweetalert2';

// constants
import { URLConstants } from '../../constants/routerLink-constants';

// environment
import { environment } from 'src/environments/environment';

// service
import { LocalStorageService } from './local-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  authorised: any = false;
  public API_URL = '';
  public platformId: any;

  constructor(
    public router: Router,
    public Http: HttpClient,
    public localStorageService: LocalStorageService,
    public spinnerService: NgxSpinnerService,
    injector: Injector
  ) {
    this.API_URL = environment.apiUrl;
    this.platformId = injector.get(PLATFORM_ID);
  }

  /*******************************************************************************************
  @PURPOSE      	: Call api.
  @Parameters 	  : {
                      url : <url of api>
                      data : <data object (JSON)>
                      method : String (get, post)
                      isForm (Optional) : Boolean - to call api with form data
                      isPublic (Optional) : Boolean - to call api without auth header
                    }
  @RETURN         : Data obtain for API
  /*****************************************************************************************/
  public showSkeletonLoader: boolean;
  callApi(url, data, method, isPublic?, isForm?, html?): Promise<any> {
    this.showSkeletonLoader = true;
    let industry = this.localStorageService.getToken('industry');
    let institution = this.localStorageService.getToken('institution');
    if (industry === null || industry === '') {
      industry = 'healthcare';
    }
    if (institution === null || institution === '') {
      institution = '';
    }
    let headers;
    if (isPublic) {
      headers = new HttpHeaders({
        'content-Type': 'application/json',
        deviceId: 'web',
        industry: industry,
        institution: institution,
      });
    } else if (html) {
      headers = new HttpHeaders({
        'content-Type': 'text/html',
        Authorization: this.localStorageService.getToken('accessToken'),
        industry: industry,
        institution: institution,
      });
    } else {
      headers = new HttpHeaders({
        'content-Type': 'application/json',
        Authorization: this.localStorageService.getToken('accessToken'),
        deviceId: 'web',
        industry: industry,
        institution: institution,
      });
    }
    if (isForm) {
      headers = new HttpHeaders({
        Authorization: this.localStorageService.getToken('accessToken'),
        industry: industry,
        institution: institution,
      });
    }
    return new Promise((resolve, reject) => {
      if (method === 'post') {
        this.Http.post(this.API_URL + url, data, { headers }).subscribe(
          (value) => {
            this.showSkeletonLoader = false;
            resolve(value);
          },
          (error) => {
            this.error(error);
          }
        );
      } else if (method === 'get') {
        this.Http.get(this.API_URL + url, { headers, params: data }).subscribe(
          (value) => {
            this.showSkeletonLoader = false;
            resolve(value);
          },
          (error) => {
            this.error(error);
          }
        );
      } else if (method === 'put') {
        this.Http.put(this.API_URL + url, data, { headers }).subscribe(
          (value) => {
            this.showSkeletonLoader = false;
            resolve(value);
          },
          (error) => {}
        );
      } else if (method === 'delete') {
        this.Http.delete(this.API_URL + url, { headers }).subscribe(
          (value) => {
            this.showSkeletonLoader = false;
            resolve(value);
          },
          (error) => {
            this.error(error);
          }
        );
      } else if (method === 'patch') {
        this.Http.patch(this.API_URL + url, data, { headers }).subscribe(
          (value) => {
            this.showSkeletonLoader = false;
            resolve(value);
          },
          (error) => {
            this.error(error);
          }
        );
      }
    });
  }

  callApiObservable(url, data) {
    const headers = new HttpHeaders({
      Authorization: this.localStorageService.getToken('accessToken'),
      industry: this.localStorageService.getToken('industry'),
      institution: this.localStorageService.getToken('institution'),
    });
    return this.Http.post(this.API_URL + url, data, { headers }).pipe(
      map((rsp) => rsp)
    );
  }
  /*****************************************************************************************/

  /*****************************************************************************************
  @PURPOSE      : To Show session LogOut popup
  @PARAMETERS   : NA
  @RETURN       : NA
  /*****************************************************************************************/
  sessionLogOut() {
    this.localStorageService.clearToken();
    swal({
      position: 'center',
      type: 'error',
      text: 'Session Timeout',
      showConfirmButton: false,
      timer: 1800,
      customClass: 'custom-toaster',
    });
    this.router.navigate([URLConstants.HOMEPAGE]);
  }
  /****************************************************************************************/

  /*****************************************************************************************
  @PURPOSE      : To Show error on status 401, 422 or any other error
  @PARAMETERS   : NA
  @RETURN       : NA
  /*****************************************************************************************/
  error(error) {
    this.spinnerService.hide();
    if (error.status === 401) {
      this.sessionLogOut();
    } else if (error.status === 422) {
      swal({
        position: 'center',
        type: 'error',
        text: 'Login Failed. Invalid credentials!',
        showConfirmButton: false,
        timer: 1800,
        customClass: 'custom-toaster',
      });
    } else {
      swal({
        position: 'center',
        type: 'error',
        text: 'Internal Server Error',
        showConfirmButton: false,
        timer: 1800,
        customClass: 'custom-toaster',
      });
    }
  }
  /*****************************************************************************************/
  // *************************************************************//
  //@Purpose : To check server or browser
  //*************************************************************//
  isBrowser() {
    if (isPlatformBrowser(this.platformId)) {
      return true;
    } else {
      return false;
    }
  }
}
