import { Component, OnInit, TemplateRef } from '@angular/core';
import {
  BreakpointObserver,
  BreakpointState,
  Breakpoints,
} from '@angular/cdk/layout';
import * as _ from 'lodash';
import { RestrictKeyPressService } from '../../common/services/service/restrict-key-press.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
// Constants
import { callAPIConstants } from '../../common/constants/callAPI-constants';
import { URLConstants } from '../../common/constants/routerLink-constants';

// Service
import { CommonService } from '../../common/services/service/common.service';
import { ShowErrorService } from '../../common/services/service/show-error.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/common/services/service/local-storage.service';
import { CloseModalService } from '../../common/services/service/close-modal.service';
import { GlobalLanguageService } from 'src/app/common/services/service/global-language.service';
import { GlobalFilterService } from 'src/app/common/services/service/global-filter.service';
@Component({
  selector: 'app-signuponboarding',
  templateUrl: './signuponboarding.component.html',
  styles: [],
})
export class SignuponboardingComponent implements OnInit {
  public resetForm: FormGroup;
  public signupForm: FormGroup;
  public submitted: boolean = false;
  public callAPIConstants = callAPIConstants;
  public URLConstants = URLConstants;
  public signupToken: any;
  public passstatus: boolean = false;
  public confirmPasswordStatus: boolean = false;
  public hideImage: Boolean = true;
  public showGetSetup: Boolean = false;
  public showSetPassword: Boolean = true;
  public regionData: any;
  public healthcareArr: Array<any> = [];
  public deparmentArr: Array<any> = [];
  public countriesArr: Array<any> = [];
  public aviationCountriesArr: Array<any> = [];
  public regionsArr: Array<any> = [];
  public aviationRegionsArr: Array<any> = [];
  public subregionsArr: Array<any> = [];
  public tempRegionsArr: Array<any> = [];
  public tempAviationRegionsArr: Array<any> = [];
  public tempSubregionsArr: Array<any> = [];
  public carouselData: Array<any> = [1, 2, 3, 4, 5];
  public showingElement = 0;
  public value = 0;
  public onboardingSteps: Array<any> = [];
  public userCountryForm: FormGroup;
  public userRegionsForm: FormGroup;
  public userRegionFormAviation: FormGroup;
  public userDepartmentForm: FormGroup;
  public userExperienceForm: FormGroup;
  public userHospitalForm: FormGroup;
  public countrySubmitted: boolean = false;
  public regionsSubmitted: boolean = false;
  public airlineSubmitted: boolean = false;
  public typeSubmitted: boolean = false;
  public expertiseSubmitted: boolean = false;
  public placeOfServiceSubmitted: boolean = false;
  public experienceSubmitted: boolean = false;
  public departmentSubmitted: boolean = false;
  public onboardingCompleted: boolean = false;
  public showInitialModal: boolean = true;
  public globalSignup: boolean = false;
  public sessionAnalyticsToken: String = null;
  public showIndustrySelection: boolean = true;
  public selectedIndustry: String = '';
  public userIndustry: String = '';
  public userTypeForm: FormGroup;
  public userPlaceOfServiceForm: FormGroup;
  public userAirlineForm: FormGroup;
  public typesArr: Array<any> = [
    'Military',
    'Private',
    'Commercial',
    'Airline',
  ];
  public placeOfServiceArr: Array<any> = [];
  public userExpertiseForm: FormGroup;
  public tempAirlinesArr: Array<any> = [];
  public airlinesArr: Array<any> = [];
  public tempPlaceOfServiceArr: Array<any> = [];
  public expertiseArr: Array<any> = [];
  languageArr = [
    { name: 'English', value: 'en' },
    { name: 'Deutsch', value: 'de' },
    { name: 'French', value: 'fr' },
    // Add more languages if needed
  ];
  public selectedLanguage: any;
  public modalRef: BsModalRef;
  public privacyPlicy: any = {};
  public termsAndConditions: any;
  public ip = '';
  public selectedCountry: String = '';
  public designationArrTemp: Array<any> = [];
  public deparmentArrTemp: Array<any> = [];
  public rolesArrTemp: Array<any> = [];
  public positionArrTemp: Array<any> = [];
  public designationArr: Array<any> = [];
  public positionArr: Array<any> = [];
  public rolesArr: Array<any> = [];
  public selectedDesignation: String = '';
  public positionConditionArray: Array<String> = [
    'Medicine',
    'Nursing',
    'Allied health',
  ];
  public otherDepartmentSelected: Boolean = false;
  public userProfileData: any = {};
  countriesSelected: String[] = [
    'Canada',
    'United States',
    'Australia',
    'New Zealand',
    'United Kingdom',
    'Ireland',
  ];
  public otherSubspecialtySelected: Boolean = false;
  public subspecialtyArr: Array<any> = [];
  public subspecialtyArrTemp: Array<any> = [];
  subspecialSelected: String[] = ['United States', 'Australia'];
  constructor(
    private formBuilder: FormBuilder,
    public showErrorService: ShowErrorService,
    private commonService: CommonService,
    private spinnerService: NgxSpinnerService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService,
    public breakpointObserver: BreakpointObserver,
    public closeModalService: CloseModalService,
    public restrictKeyPressService: RestrictKeyPressService,
    public globalLanguageService: GlobalLanguageService,
    public modalService: BsModalService,
    public GlobalFilterService: GlobalFilterService
  ) {
    this.ip = localStorageService.getToken('ip');
    this.activatedRoute.queryParams.subscribe((params) => {
      this.signupToken = params['signup'] ? params['signup'] : '';
      this.signupToken = params['signup'] ? params['signup'] : '';
      this.globalSignup = params['globalSignup']
        ? params['globalSignup']
        : false;
      if (this.globalSignup) {
        this.showIndustrySelection = true;
      } else {
        let industry = params['industry'] ? params['industry'] : '';
        this.localStorageService.setToken('industry', industry);
        this.showIndustrySelection = false;
      }
    });
  }

  ngOnInit(): void {
    // if (this.router.url === '/feed') {
    //   this.showInitialModal = false;
    // }
    this.resetForm = this.formBuilder.group(
      {
        password: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/
            ),
          ],
        ],
        confirmPassword: ['', [Validators.required]],
      },
      { validator: this.passwordMatchValidator }
    );

    this.signupForm = this.formBuilder.group(
      {
        email: [
          '',
          [
            Validators.required,
            Validators.pattern(
              '^([_a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,5}))|(d+$)$'
            ),
          ],
        ],
        password: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/
            ),
          ],
        ],
        confirmPassword: ['', [Validators.required]],
        agreeTermsAndConditions: [false, [Validators.requiredTrue]],
      },
      { validator: this.passwordMatchValidator }
    );

    this.userRegionsForm = this.formBuilder.group({
      region: [null, [Validators.required]],
      subRegion: [null, [Validators.required]],
    });

    this.userCountryForm = this.formBuilder.group({
      country: [null, [Validators.required]],
    });

    this.userDepartmentForm = this.formBuilder.group({
      department: [null, [Validators.required]],
      designation: [null],
      otherRole: [''],
      otherDepartment: [''],
      otherDesignation: [''],
      role: [null],
      position: [null],
      otherPosition: [''],
      subspecialties: [null],
      otherSubspecialties: [''],
    });

    this.userExperienceForm = this.formBuilder.group({
      experience: ['', [Validators.required]],
    });

    this.userHospitalForm = this.formBuilder.group({
      hospital: [''],
    });

    this.userAirlineForm = this.formBuilder.group({
      airline: [null, [Validators.required]],
    });

    this.userTypeForm = this.formBuilder.group({
      type: [null, [Validators.required]],
    });

    this.userCountryForm = this.formBuilder.group({
      country: [null, [Validators.required]],
    });

    this.userPlaceOfServiceForm = this.formBuilder.group({
      placeOfService: [null, [Validators.required]],
    });

    this.userExpertiseForm = this.formBuilder.group({
      expertise: [null, [Validators.required]],
    });

    this.userRegionFormAviation = this.formBuilder.group({
      region: [null, [Validators.required]],
    });

    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
      ])
      .subscribe((state: BreakpointState) => {
        if (state.breakpoints[Breakpoints.XSmall]) {
          this.hideImage = false;
        } else if (state.breakpoints[Breakpoints.Small]) {
          this.hideImage = false;
        } else if (state.breakpoints[Breakpoints.Medium]) {
          this.hideImage = false;
        } else if (state.breakpoints[Breakpoints.Large]) {
          this.hideImage = true;
        } else {
          this.hideImage = true;
        }
      });

    this.globalLanguageService.getGlobalLanguage().subscribe((value) => {
      this.getTermsAndConditions();
      this.getPrivacyPolicy();
    });

    this.sessionAnalyticsToken = window.sessionStorage.getItem(
      'sessionAnalyticsToken'
    );

    this.getPrivacyPolicy();
    this.getTermsAndConditions();
  }

  passwordMatchValidator(frm: FormGroup) {
    return frm.controls['password'].value ===
      frm.controls['confirmPassword'].value
      ? null
      : { mismatch: true };
  }

  passwordEvent(field) {
    if (field === 'password') {
      this.passstatus = !this.passstatus;
    } else {
      this.confirmPasswordStatus = !this.confirmPasswordStatus;
    }
  }

  /****************************************************************************
     @Purpose     : clearValueForNgselect
     @Parameter   : Control
     @Return      : Na
  /****************************************************************************/
  resetSelection(control) {
    if (control === 'designation') {
      this.userDepartmentForm.controls['role'].setValue(null);
      this.userDepartmentForm.controls['department'].setValue(null);
      this.rolesArr = this.rolesArrTemp;
      let roles = this.rolesArr.filter(
        (role) => role.country === this.selectedCountry
      );
      this.rolesArr = roles;
      if (this.countriesSelected.includes(this.selectedCountry)) {
        let filteredRoles = this.rolesArrTemp.filter(
          (role) => role.country === this.selectedCountry
        );
        let departmentIds = filteredRoles
          .map((role) => role.departments.map((dept) => dept._id))
          .reduce((acc, ids) => acc.concat(ids), []);
        this.deparmentArr = this.deparmentArrTemp.filter((dept) =>
          departmentIds.includes(dept._id)
        );
        const subspecialArr = this.deparmentArr
          .map((department) => department.subspecialties)
          .reduce(
            (accumulator, currentValue) => accumulator.concat(currentValue),
            []
          );
        this.subspecialtyArr = subspecialArr;
      } else {
        this.deparmentArr = this.deparmentArrTemp;
      }
      this.rolesArr.unshift({ name: 'Other Role' });
      this.deparmentArr.unshift({ name: 'Other Department' });
      this.subspecialtyArr.unshift({ name: 'Other Subspecialty' });
      this.otherSubspecialtySelected = false;
      this.otherDepartmentSelected = false;
    }
    if (control === 'role') {
      this.userDepartmentForm.controls['department'].setValue(null);
      this.userDepartmentForm.controls['subspecialties'].setValue(null);
      this.userDepartmentForm.controls['otherSubspecialties'].setValue('');
      if (this.countriesSelected.includes(this.selectedCountry)) {
        let filteredRoles = this.rolesArrTemp.filter(
          (role) => role.country === this.selectedCountry
        );
        let departmentIds = filteredRoles
          .map((role) => role.departments.map((dept) => dept._id))
          .reduce((acc, ids) => acc.concat(ids), []);
        this.deparmentArr = this.deparmentArrTemp.filter((dept) =>
          departmentIds.includes(dept._id)
        );
        const subspecialArr = this.deparmentArr
          .map((department) => department.subspecialties)
          .reduce(
            (accumulator, currentValue) => accumulator.concat(currentValue),
            []
          );
        this.subspecialtyArr = subspecialArr;
      } else {
        this.deparmentArr = this.deparmentArrTemp;
      }
      this.deparmentArr.unshift({ name: 'Other Department' });
    }
  }

  resetFormSubmit() {
    this.submitted = true;
    if (this.resetForm.valid) {
      let resetPasswordData = {
        token: this.signupToken,
        password: this.resetForm.value.password,
      };
      this.spinnerService.show();
      this.commonService
        .callApi(
          this.callAPIConstants.userSetPassword,
          resetPasswordData,
          'post',
          true,
          false
        )
        .then((success) => {
          this.spinnerService.hide();
          if (success.status === 1) {
            this.signInSubmit(success.data.oneTimeSiginToken);
          } else {
            this.showErrorService.popToast('error', success.message);
            this.resetForm.reset();
          }
        });
    }
  }

  registerFormSubmit() {
    this.submitted = true;
    if (this.signupForm.valid) {
      this.closeModalService.sendCloseEvent(true);
      let data = {
        emailId: this.signupForm.value.email,
        password: this.signupForm.value.password,
        termsAndCondition: this.signupForm.value.agreeTermsAndConditions,
        industry: this.selectedIndustry,
      };
      this.spinnerService.show();
      this.commonService
        .callApi(
          this.callAPIConstants.userSignupOnboarding,
          data,
          'post',
          true,
          false
        )
        .then((success) => {
          this.spinnerService.hide();
          if (success.status === 1) {
            success.showOtp
              ? this.showErrorService.popToastWithoutTimer(
                  'success',
                  success.message
                )
              : this.showErrorService.popToastWithoutTimer(
                  'success',
                  success.message
                );

            if (this.sessionAnalyticsToken != null) {
              this.updateQrCampaignVisits(this.sessionAnalyticsToken);
            }
          } else {
            if (success.isExists) {
              this.router.navigate([URLConstants.HOMEPAGE]);
            }
            this.showErrorService.popToastWithoutTimer('info', success.message);
            this.resetForm.reset();
          }
        });
    }
  }

  /****************************************************************************
          @Purpose     : send analytics
          @Parameter   : Na
          @Return      : Na
  /****************************************************************************/
  updateQrCampaignVisits(token) {
    this.commonService
      .callApi(
        this.callAPIConstants.updateQrVisits,
        { token: token, type: 'signup' },
        'post',
        true,
        false
      )
      .then((success) => {});
  }

  departmentAdd(value) {
    this.userDepartmentForm.controls['otherDepartment'].setValue('');
    this.subspecialtyArr.unshift({ name: 'Other Subspecialty' });
    if (value.name === 'Other Department') {
      this.otherDepartmentSelected = true;
      this.userDepartmentForm.controls['department'].setValue([
        'Other Department',
      ]);
    } else {
      const deptArray = this.userDepartmentForm.value.department;
      const arr = deptArray.filter((item) => item !== 'Other Department');
      this.userDepartmentForm.controls['department'].setValue(arr);
      this.otherDepartmentSelected = false;
    }
  }

  deptClear(value) {
    this.userDepartmentForm.controls['otherDepartment'].setValue('');
    if (value.label === 'Other Department') {
      this.otherDepartmentSelected = false;
    }
  }

  /*************************************************************
	  @Purpose     : SignIn
	  @Parameter   : NA
	  @Return      : NA
	/*************************************************************/
  signInSubmit(token) {
    let sigInData = {
      token: token,
    };
    this.commonService
      .callApi(
        this.callAPIConstants.OneTimeSigIn,
        sigInData,
        'post',
        true,
        false
      )
      .then((successSigInData) => {
        if (successSigInData.status === 1) {
          this.showGetSetup = true;
          this.showSetPassword = false;
          // this.modalRef.hide();
          this.localStorageService.setToken(
            'accessToken',
            successSigInData.access_token
          );
          this.localStorageService.setToken(
            'username',
            successSigInData.data.username
          );
          this.localStorageService.setToken(
            'currentUser',
            JSON.stringify(successSigInData.data)
          );
          this.localStorageService.setToken(
            'industry',
            successSigInData.data.industry
          );
          this.localStorageService.setToken(
            'institution',
            successSigInData.data.institution
          );
          this.localStorageService.setToken(
            'filterToggle',
            JSON.stringify(false)
          );
          this.localStorageService.setToken(
            'scrollIndexSolution',
            JSON.stringify(0)
          );
          this.localStorageService.setToken(
            'dontShowOnboarding',
            JSON.stringify(true)
          );
          const preferredLanguage = successSigInData.data.preferredLanguage;
          this.globalLanguageService.sendGlobalLanguage(preferredLanguage);
          this.localStorageService.setToken('scrollIndex', JSON.stringify(0));
          this.router.navigate([URLConstants.FEED]);
        } else {
          this.showErrorService.popToast('error', successSigInData.message);
        }
      });
  }

  /*************************************************************
	  @Purpose     : start setup
	  @Parameter   : NA
	  @Return      : NA
	/*************************************************************/
  startSetup() {
    // this.closeModalService.sendCloseEvent(true);
    // this.router.navigate([URLConstants.FEED]);

    // this.setUserDefaultData();
    this.getDomainDetails();
    this.getOnboardingData();
  }

  // closeModal() {
  //   this.closeModalService.sendCloseEvent(true);
  // }

  navigate(index) {
    if (index === 2) {
      this.countrySubmitted = true;
      let updateUserData;
      if (this.userCountryForm.valid) {
        this.selectedCountry = this.userCountryForm.value.country;
        if (this.countriesSelected.includes(this.selectedCountry)) {
          updateUserData = {
            country: this.userCountryForm.value.country
              ? this.userCountryForm.value.country
              : '',
            designation: '',
            position: '',
            healthcareRole: '',
            otherDepartment: '',
            otherPosition: '',
            otherHealthcareRole: '',
            otherDesignation: '',
            department: [],
            otherSubspecialties: '',
            subspecialties: [],
          };
        } else {
          updateUserData = {
            country: this.userCountryForm.value.country
              ? this.userCountryForm.value.country
              : '',
          };
        }
        this.editProfile(updateUserData, index);
      }
    }

    if (index === 3) {
      this.regionsSubmitted = true;
      if (this.userRegionsForm.valid) {
        let updateUserData = {
          region: this.userRegionsForm.value.region
            ? this.userRegionsForm.value.region
            : '',
          subRegion: this.userRegionsForm.value.subRegion
            ? this.userRegionsForm.value.subRegion
            : '',
        };
        this.editProfile(updateUserData, index);
      }
    }

    if (index === 4) {
      this.departmentSubmitted = true;
      if (
        !_.isEmpty(this.userDepartmentForm.value.department) &&
        this.userDepartmentForm.value.department[0] === 'Other Department'
      ) {
        if (this.userDepartmentForm.value.otherDepartment === '') {
          this.userDepartmentForm
            .get('department')
            .setValidators([Validators.required]);
          this.userDepartmentForm.get('department').updateValueAndValidity();
          this.userDepartmentForm
            .get('department')
            .setErrors({ required: true });
        } else {
          this.userDepartmentForm.get('department').clearValidators();
          this.userDepartmentForm.get('department').updateValueAndValidity();
        }
      } else {
        if (_.isEmpty(this.userDepartmentForm.value.department)) {
          this.userDepartmentForm
            .get('department')
            .setValidators([Validators.required]);
          this.userDepartmentForm.get('department').updateValueAndValidity();
          this.userDepartmentForm
            .get('department')
            .setErrors({ required: true });
        }
      }
      if (this.userDepartmentForm.valid) {
        let updateUserData = {
          department:
            !_.isEmpty(this.userDepartmentForm.value.department) &&
            !this.otherDepartmentSelected
              ? this.userDepartmentForm.value.department
              : [],
          subspecialties:
            !_.isEmpty(this.userDepartmentForm.value.subspecialties) &&
            !this.otherSubspecialtySelected
              ? this.userDepartmentForm.value.subspecialties
              : [],
          designation:
            this.userDepartmentForm.value.designation &&
            this.userDepartmentForm.value.designation !== 'Other Designation'
              ? this.userDepartmentForm.value.designation
              : '',
          position:
            this.userDepartmentForm.value.position &&
            this.userDepartmentForm.value.position !== 'Other Position'
              ? this.userDepartmentForm.value.position
              : '',
          healthcareRole:
            this.userDepartmentForm.value.role &&
            this.userDepartmentForm.value.role !== 'Other Role'
              ? this.userDepartmentForm.value.role
              : '',
          otherDesignation: this.userDepartmentForm.value.otherDesignation
            ? this.userDepartmentForm.value.otherDesignation
            : '',
          otherPosition: this.userDepartmentForm.value.otherPosition
            ? this.userDepartmentForm.value.otherPosition
            : '',
          otherHealthcareRole: this.userDepartmentForm.value.otherRole
            ? this.userDepartmentForm.value.otherRole
            : '',
          otherDepartment: this.userDepartmentForm.value.otherDepartment
            ? this.userDepartmentForm.value.otherDepartment
            : '',
          otherSubspecialties: this.userDepartmentForm.value.otherSubspecialties
            ? this.userDepartmentForm.value.otherSubspecialties
            : '',
        };
        this.editProfile(updateUserData, index);
      }
    }

    if (index === 5) {
      this.experienceSubmitted = true;
      if (this.userExperienceForm.valid) {
        let updateUserData = {
          yearsOfExperience: this.userExperienceForm.value.experience
            ? this.userExperienceForm.value.experience
            : '',
        };
        this.editProfile(updateUserData, index);
      }
    }

    if (index === 6) {
      this.value = this.value + 1;
      let updateUserData = {
        hospital: this.userHospitalForm.value.hospital
          ? this.userHospitalForm.value.hospital
          : '',
      };
      this.editProfile(updateUserData, index);
    }
  }

  navigateBack(index) {
    this.showingElement = index;
  }

  /****************************************************************************
       @Purpose     : getDomainDetails
       @Parameter   : $event
       @Return      : NA
  /****************************************************************************/
  getDomainDetails() {
    this.commonService
      .callApi(
        this.callAPIConstants.profileListingDetails,
        '',
        'get',
        false,
        false
      )
      .then((success) => {
        if (success.status === 1) {
          this.regionData = success.data;
          this.healthcareArr = this.regionData.regionList;
          this.deparmentArr = this.regionData.departmentList;
          this.countriesArr = this.regionData.countryList;
          this.aviationCountriesArr = this.regionData.aviationCountryList;
          this.subregionsArr = this.regionData.subRegionList;
          this.placeOfServiceArr = this.regionData.placeOfServiceList;
          this.airlinesArr = this.regionData.airlineList;
          this.tempAirlinesArr = this.airlinesArr;
          this.tempPlaceOfServiceArr = this.placeOfServiceArr;
          this.expertiseArr = this.regionData.expertiseList;
          this.regionsArr = this.regionData.regionList;
          this.aviationRegionsArr = this.regionData.aviationRegionList;
          this.tempAviationRegionsArr = this.regionData.aviationRegionList;
          this.tempRegionsArr = this.regionsArr;
          this.tempSubregionsArr = this.subregionsArr;
          this.deparmentArrTemp = this.regionData.departmentList;
          this.designationArr = this.regionData.designationList;
          this.designationArrTemp = this.regionData.designationList;
          this.positionArr = this.regionData.positionList;
          this.positionArrTemp = this.regionData.positionList;
          this.rolesArr = this.regionData.rolesList;
          this.rolesArrTemp = this.regionData.rolesList;
          this.subspecialtyArr = this.regionData.subspecialtyList;
          this.subspecialtyArrTemp = this.regionData.subspecialtyList;
          this.setUserDefaultData();
        } else {
          // this.showErrorService.popToast('error', success.message);
          if (success.message === 'Password needs to be changed.') {
            setTimeout(() => {
              this.router.navigate([URLConstants.SETTINGS]);
            }, 2500);
          }
        }
      });
  }

  /****************************************************************************
     @Purpose     : SetValueForNgselect
     @Parameter   : Control,value
     @Return      : Na
  /****************************************************************************/
  setValueForSelect(control, value) {
    if (control === 'country') {
      this.regionsArr = this.tempRegionsArr;
      let regions = this.regionsArr.filter(
        (region) => region.country === value.name
      );
      this.regionsArr = regions;
      if (this.countriesSelected.includes(value.name)) {
        let filteredRoles = this.rolesArrTemp.filter(
          (role) => role.country === value.name
        );
        let departmentIds = filteredRoles
          .map((role) => role.departments.map((dept) => dept._id))
          .reduce((acc, ids) => acc.concat(ids), []);
        this.deparmentArr = this.deparmentArrTemp.filter((dept) =>
          departmentIds.includes(dept._id)
        );
        const subspecialArr = this.deparmentArr
          .map((department) => department.subspecialties)
          .reduce(
            (accumulator, currentValue) => accumulator.concat(currentValue),
            []
          );
        this.subspecialtyArr = subspecialArr;
      } else {
        this.deparmentArr = this.deparmentArrTemp;
      }
    }
    if (control === 'region') {
      this.subregionsArr = this.tempSubregionsArr;
      let subRegion = value.subRegionList;
      this.subregionsArr = subRegion;
      this.userRegionsForm.get('subRegion').setValue(null);
    }

    if (control === 'designation') {
      this.selectedDesignation = value.name;
      this.userDepartmentForm.controls['role'].setValue(null);
      this.userDepartmentForm.controls['department'].setValue(null);
      this.userDepartmentForm.controls['otherDesignation'].setValue('');
      this.userDepartmentForm.controls['subspecialties'].setValue(null);
      this.userDepartmentForm.controls['otherSubspecialties'].setValue('');
      this.otherDepartmentSelected = false;
      this.otherSubspecialtySelected = false;
      this.rolesArr = this.rolesArrTemp;
      //prettier-ignore
      let roles = this.rolesArr.filter(
        (role) => (role.name != 'Other Role' && ( role.designation.name === this.selectedDesignation) && (role.country === this.selectedCountry))
      );
      this.rolesArr = roles;
      if (this.countriesSelected.includes(this.selectedCountry)) {
        let filteredRoles = roles;
        let departmentIds = filteredRoles
          .map((role) => role.departments.map((dept) => dept._id))
          .reduce((acc, ids) => acc.concat(ids), []);
        this.deparmentArr = this.deparmentArrTemp.filter((dept) =>
          departmentIds.includes(dept._id)
        );
        const subspecialArr = this.deparmentArr
          .map((department) => department.subspecialties)
          .reduce(
            (accumulator, currentValue) => accumulator.concat(currentValue),
            []
          );
        this.subspecialtyArr = subspecialArr;
      }
      this.rolesArr.unshift({ name: 'Other Role' });
      if (!this.positionConditionArray.includes(this.selectedDesignation)) {
        this.userDepartmentForm.controls['position'].setValue(null);
        this.userDepartmentForm.controls['otherPosition'].setValue('');
      }
    }

    if (control === 'role') {
      this.deparmentArr = this.deparmentArrTemp;
      let departments = this.rolesArr.filter(
        (role) => role.name === value.name
      );
      if (!_.isEmpty(departments)) {
        if (value.name !== 'Other Role') {
          this.deparmentArr = departments[0].departments;
        } else {
          this.deparmentArr = [];
        }
      } else {
        this.deparmentArr = [];
      }
      this.deparmentArr.unshift({ name: 'Other Department' });
      this.subspecialtyArr.unshift({ name: 'Other Subspecialty' });
      this.userDepartmentForm.controls['otherRole'].setValue('');
      this.userDepartmentForm.controls['otherDepartment'].setValue('');
      this.userDepartmentForm.controls['department'].setValue(null);
      this.userDepartmentForm.controls['subspecialties'].setValue(null);
      this.userDepartmentForm.controls['otherSubspecialties'].setValue('');

      this.otherDepartmentSelected = false;
      this.otherSubspecialtySelected = false;
    }
  }

  industrySelection(industry) {
    this.showIndustrySelection = false;
    this.selectedIndustry = industry;
    this.localStorageService.setToken('industry', industry);
  }

  /****************************************************************************
   @Purpose     : GetUserOnboardingData
   @Parameter   : Na
   @Return      : NA
   /****************************************************************************/
  getOnboardingData() {
    this.commonService
      .callApi(
        this.callAPIConstants.userGetOnboardingData,
        '',
        'get',
        false,
        false
      )
      .then((success) => {
        if (success.status === 1) {
          this.onboardingSteps = success.data.onBoardingSteps;
          this.userIndustry = success.data.industry;
          success.data.onBoardingSteps.map((element) => {
            if (element.completed) {
              this.value = this.value + 1;
            }
          });

          this.showingElement = _.find(
            this.onboardingSteps,
            function (elemennt) {
              return !elemennt.completed;
            }
          ).step;

          this.showInitialModal = false;
        } else {
          this.showErrorService.popToast('error', success.message);
          if (success.message === 'Password needs to be changed.') {
            setTimeout(() => {
              this.router.navigate([URLConstants.SETTINGS]);
            }, 2500);
          }
        }
      });
  }

  /****************************************************************************
     @Purpose     : SetValueForNgselect
     @Parameter   : Control,value
     @Return      : Na
  /****************************************************************************/
  setValueForSelectAviation(control, value) {
    this.userRegionsForm.get('region').setValue(null);
    this.userPlaceOfServiceForm.get('placeOfService').setValue(null);
    this.userAirlineForm.get('airline').setValue(null);

    this.userPlaceOfServiceForm.reset();
    this.userRegionsForm.reset();
    this.userPlaceOfServiceForm.reset();

    this.placeOfServiceSubmitted = false;
    this.regionsSubmitted = false;
    this.airlineSubmitted = false;

    if (control === 'country') {
      this.placeOfServiceArr = this.tempPlaceOfServiceArr;
      this.airlinesArr = this.tempAirlinesArr;
      this.aviationRegionsArr = this.tempAviationRegionsArr;
      let aviationRegions = this.aviationRegionsArr.filter(
        (region) => region.country === value.name
      );
      this.aviationRegionsArr = aviationRegions;

      let services = this.placeOfServiceArr.filter(
        (service) => service.country === value.name
      );
      this.placeOfServiceArr = services;

      let airlines = this.airlinesArr.filter(
        (airline) => airline.country === value.name
      );
      this.airlinesArr = airlines;
    }
  }

  navigateAviation(index, type) {
    if (index === 2) {
      this.countrySubmitted = true;
      if (this.userCountryForm.valid) {
        let updateUserData = {
          country: this.userCountryForm.value.country
            ? this.userCountryForm.value.country
            : '',
          // region: '',
          // aviationPlaceOfService: '',
          // airline: '',
        };
        this.editProfile(updateUserData, index);

        this.userRegionsForm.get('region').setValue(null);
        this.userPlaceOfServiceForm.get('placeOfService').setValue(null);
        this.userAirlineForm.get('airline').setValue(null);

        this.userPlaceOfServiceForm.reset();
        this.userRegionsForm.reset();
        this.userPlaceOfServiceForm.reset();

        this.placeOfServiceSubmitted = false;
        this.regionsSubmitted = false;
        this.airlineSubmitted = false;
      }
    }

    if (index === 3) {
      this.typeSubmitted = true;
      if (this.userTypeForm.valid) {
        let updateUserData = {
          aviationType: this.userTypeForm.value.type,
        };
        this.editProfile(updateUserData, index);
      }
    }

    if (index === 4) {
      if (type === 'service') {
        this.placeOfServiceSubmitted = true;
        if (this.userPlaceOfServiceForm.valid) {
          let updateUserData = {
            aviationPlaceOfService: this.userPlaceOfServiceForm.value
              .placeOfService
              ? this.userPlaceOfServiceForm.value.placeOfService
              : '',
          };
          this.editProfile(updateUserData, index);
        }
      }

      if (type === 'region') {
        this.regionsSubmitted = true;
        if (this.userRegionFormAviation.valid) {
          let updateUserData = {
            region: this.userRegionFormAviation.value.region
              ? this.userRegionFormAviation.value.region
              : '',
          };
          this.editProfile(updateUserData, index);
        }
      }

      if (type === 'airline') {
        this.airlineSubmitted = true;
        if (this.userAirlineForm.valid) {
          let updateUserData = {
            airline: this.userAirlineForm.value.airline
              ? this.userAirlineForm.value.airline
              : '',
          };
          this.editProfile(updateUserData, index);
        }
      }
    }

    if (index === 5) {
      this.expertiseSubmitted = true;
      if (this.userExpertiseForm.valid) {
        let updateUserData = {
          expertise: this.userExpertiseForm.value.expertise
            ? this.userExpertiseForm.value.expertise
            : '',
        };
        this.editProfile(updateUserData, index);
      }
    }

    if (index === 6) {
      this.experienceSubmitted = true;
      if (this.userExperienceForm.valid) {
        let updateUserData = {
          yearsOfExperience: this.userExperienceForm.value.experience
            ? this.userExperienceForm.value.experience
            : '',
        };
        this.editProfile(updateUserData, index);
      }
    }
  }

  /****************************************************************************
   @Purpose     : editUserOnboardingData
   @Parameter   : Na
   @Return      : NA
   /****************************************************************************/
  editOnboardingData() {
    this.commonService
      .callApi(
        this.callAPIConstants.userEditOnboardingData,
        {
          onboardingSteps: this.onboardingSteps,
          completed: this.onboardingCompleted,
        },
        'post',
        false,
        false
      )
      .then((success) => {
        if (success.status === 1) {
          if (
            !success.data.onboardingCompleted &&
            !success.data.dontShowOnboarding
          ) {
            this.value = 0;
            this.onboardingSteps = success.data.onBoardingSteps;
            success.data.onBoardingSteps.map((element) => {
              if (element.completed) {
                this.value = this.value + 1;
              }
            });

            // this.showingElement = _.find(
            //   this.onboardingSteps,
            //   function (elemennt) {
            //     return !elemennt.completed;
            //   }
            // ).step;
          }
        } else {
          this.showErrorService.popToast('error', success.message);
          if (success.message === 'Password needs to be changed.') {
            setTimeout(() => {
              this.router.navigate([URLConstants.SETTINGS]);
            }, 2500);
          }
        }
      });
  }

  /****************************************************************************
     @Purpose     : editProfile
     @Parameter   : $event
     @Return      : NA
  /****************************************************************************/
  editProfile(updateUserData, index) {
    this.commonService
      .callApi(
        this.callAPIConstants.updateUserPersonalData,
        updateUserData,
        'post',
        false,
        false
      )
      .then((success) => {
        if (success.status === 1) {
          // this.showErrorService.popToast('success', success.message);
          this.onboardingSteps[index - 2]['completed'] = true;
          if (index === 6) {
            this.onboardingCompleted = true;
          }
          console.log(this.onboardingCompleted);
          this.editOnboardingData();
          this.showingElement = index;
          this.localStorageService.setToken(
            'currentUser',
            JSON.stringify(success.data)
          );
          this.localStorageService.setToken('industry', success.data.industry);
          this.setUserDefaultData();
        } else {
          this.showErrorService.popToast('error', success.message);
        }
      });
  }

  /****************************************************************************
     @Purpose     : set profile data to onboarding
     @Parameter   : $event
     @Return      : NA
  /****************************************************************************/
  async setUserDefaultData() {
    let user = this.localStorageService.getToken('currentUser');
    let data = await JSON.parse(user);
    let regionName = data.region;
    let subRegionName = data.subRegion;
    let departmentName = data.department;
    let country = data.country;
    let experience = data.yearsOfExperience;
    let hospital = data.hospital;
    let placeOfService = data.aviationPlaceOfService;
    let aviationType = data.aviationType;
    let airline = data.airline;
    let expertise = data.expertise;
    let designation = data.designation;
    let healthcareRole = data.healthcareRole;
    let position = data.position;
    let otherDesignation = data.otherDesignation;
    let otherRole = data.otherHealthcareRole;
    let otherPosition = data.otherPosition;
    let otherDepartment = data.otherDepartment;
    let subspecialties = data.subspecialties;
    let otherSubspecialties = data.otherSubspecialties;

    this.selectedDesignation = designation;

    if (this.userIndustry === 'aviation') {
      placeOfService !== '' &&
        this.userPlaceOfServiceForm
          .get('placeOfService')
          .setValue(placeOfService);
      aviationType !== '' &&
        this.userTypeForm.get('type').setValue(aviationType);
      airline !== '' && this.userAirlineForm.get('airline').setValue(airline);
      expertise !== '' &&
        this.userExpertiseForm.get('expertise').setValue(expertise);
      regionName !== '' &&
        this.userRegionFormAviation.get('region').setValue(regionName);
    }

    country !== '' && this.userCountryForm.get('country').setValue(country);
    regionName !== '' &&
      this.userRegionsForm.get('region').setValue(regionName);
    subRegionName !== '' &&
      this.userRegionsForm.get('subRegion').setValue(subRegionName);
    departmentName !== '' &&
      this.userDepartmentForm.get('department').setValue(departmentName);
    subspecialties !== '' &&
      this.userDepartmentForm.get('subspecialties').setValue(subspecialties);
    experience !== '' &&
      this.userExperienceForm.get('experience').setValue(experience);
    hospital !== '' && this.userHospitalForm.get('hospital').setValue(hospital);
    designation !== ''
      ? this.userDepartmentForm.get('designation').setValue(designation)
      : this.userDepartmentForm.get('designation').setValue(null);
    healthcareRole !== ''
      ? this.userDepartmentForm.get('role').setValue(healthcareRole)
      : this.userDepartmentForm.get('role').setValue(null);
    position !== ''
      ? this.userDepartmentForm.get('position').setValue(position)
      : this.userDepartmentForm.get('position').setValue(null);
    otherDesignation !== ''
      ? this.userDepartmentForm
          .get('otherDesignation')
          .setValue(otherDesignation)
      : this.userDepartmentForm.get('otherDesignation').setValue(null);
    otherPosition !== ''
      ? this.userDepartmentForm.get('otherPosition').setValue(otherPosition)
      : this.userDepartmentForm.get('otherPosition').setValue(null);
    otherRole
      ? this.userDepartmentForm.get('otherRole').setValue(otherRole)
      : this.userDepartmentForm.get('otherRole').setValue(null);
    otherDepartment !== ''
      ? this.userDepartmentForm.get('otherDepartment').setValue(otherDepartment)
      : this.userDepartmentForm.get('otherDepartment').setValue(null);
    otherSubspecialties !== ''
      ? this.userDepartmentForm
          .get('otherSubspecialties')
          .setValue(otherSubspecialties)
      : this.userDepartmentForm.get('otherSubspecialties').setValue(null);

    if (otherDepartment === '' || otherDepartment == null) {
      this.otherDepartmentSelected = false;
    }

    if (country !== '') {
      this.selectedCountry = country;
      this.regionsArr = this.tempRegionsArr;
      let regions = this.regionsArr.filter(
        (region) => region.country === country
      );
      this.regionsArr = regions;

      if (this.userIndustry === 'aviation') {
        this.aviationRegionsArr = this.tempAviationRegionsArr;
        let aviationRegions = this.aviationRegionsArr.filter(
          (region) => region.country === country
        );
        this.aviationRegionsArr = aviationRegions;

        this.placeOfServiceArr = this.tempPlaceOfServiceArr;
        let services = this.placeOfServiceArr.filter(
          (service) => service.country === country
        );
        this.placeOfServiceArr = services;

        this.airlinesArr = this.tempAirlinesArr;
        let airlines = this.airlinesArr.filter(
          (airline) => airline.country === country
        );
        this.airlinesArr = airlines;
      }

      this.designationArr = this.designationArrTemp;
      let designations = this.designationArr.filter(
        (designation) => designation.country === country
      );
      this.designationArr = designations;

      this.rolesArr = this.rolesArrTemp;
      let roles = this.rolesArr.filter((role) => role.country === country);
      this.rolesArr = roles;

      this.positionArr = this.positionArrTemp;
      let positions = this.positionArr.filter(
        (role) => role.country === country
      );
      this.positionArr = positions;
      if (this.countriesSelected.includes(this.selectedCountry)) {
        let filteredRoles = this.rolesArrTemp.filter(
          (role) => role.country === this.selectedCountry
        );
        let departmentIds = filteredRoles
          .map((role) => role.departments.map((dept) => dept._id))
          .reduce((acc, ids) => acc.concat(ids), []);
        this.deparmentArr = this.deparmentArrTemp.filter((dept) =>
          departmentIds.includes(dept._id)
        );

        let userDept = this.deparmentArr.filter((dept) =>
          departmentName.includes(dept.name)
        );

        const subspecialArr = userDept
          .map((department) => department.subspecialties)
          .reduce(
            (accumulator, currentValue) => accumulator.concat(currentValue),
            []
          );
        this.subspecialtyArr = subspecialArr;
      }

      if (this.countriesSelected.includes(country)) {
        this.designationArr.unshift({ name: 'Other Designation' });
        this.rolesArr.unshift({ name: 'Other Role' });
        this.deparmentArr.unshift({ name: 'Other Department' });
        this.positionArr.unshift({ name: 'Other Position' });
        this.subspecialtyArr.unshift({ name: 'Other Subspecialty' });
      }
    }

    if (designation !== '') {
      this.rolesArr = this.rolesArrTemp;
      let roles = this.rolesArr.filter(
        (role) =>
          role.designation.name === designation && role.country === country
      );
      this.rolesArr = roles;
      if (this.countriesSelected.includes(this.selectedCountry)) {
        let filteredRoles = this.rolesArr;
        let departmentIds = filteredRoles
          .map((role) => role.departments.map((dept) => dept._id))
          .reduce((acc, ids) => acc.concat(ids), []);
        this.deparmentArr = this.deparmentArrTemp.filter((dept) =>
          departmentIds.includes(dept._id)
        );

        let userDept = this.deparmentArr.filter((dept) =>
          departmentName.includes(dept.name)
        );

        const subspecialArr = userDept
          .map((department) => department.subspecialties)
          .reduce(
            (accumulator, currentValue) => accumulator.concat(currentValue),
            []
          );
        this.subspecialtyArr = subspecialArr;
      }
      this.rolesArr.unshift({ name: 'Other Role' });
      // this.deparmentArr.unshift({ name: 'Other Department' });
      // this.subspecialtyArr.unshift({ name: 'Other Subspecialty' });
    }

    if (healthcareRole !== '') {
      this.deparmentArr = this.deparmentArrTemp;
      let departments = this.rolesArr.filter(
        (role) => role.name === healthcareRole
      );
      if (!_.isEmpty(departments)) {
        this.deparmentArr = departments[0].departments;
      } else {
        this.deparmentArr = [];
      }
      this.deparmentArr.unshift({ name: 'Other Department' });
    }

    if (otherDesignation) {
      this.userDepartmentForm.controls['designation'].setValue(
        'Other Designation'
      );
    }
    if (otherPosition) {
      this.userDepartmentForm.controls['position'].setValue('Other Position');
    }
    if (otherRole) {
      this.userDepartmentForm.controls['role'].setValue('Other Role');
    }
    if (otherDepartment) {
      this.userDepartmentForm.controls['department'].setValue([
        'Other Department',
      ]);
      this.otherDepartmentSelected = true;
    }

    if (this.userIndustry === 'healthcare') {
      if (country === null || regionName === null || subRegionName === null) {
        this.setUserDefaultDataUsingDomain();
      }
    }

    if (this.userIndustry === 'aviation') {
      if (country === null || aviationType === null) {
        this.setUserDefaultDataUsingDomainAviation();
      }
    }
  }

  /****************************************************************************
     @Purpose     : set profile data to onboarding
     @Parameter   : $event
     @Return      : NA
  /****************************************************************************/
  async setUserDefaultDataUsingDomain() {
    let user = this.localStorageService.getToken('currentUser');
    let data = await JSON.parse(user);
    let domainEmail = data.emailId;

    let signUpDomain = _.last(domainEmail.split('@')).toLowerCase().trim();

    this.commonService
      .callApi(
        this.callAPIConstants.userGetDomainData,
        {
          domainName: signUpDomain,
        },
        'post',
        false,
        false
      )
      .then((success) => {
        if (success.status === 1) {
          let country = success.data.region.country;
          let regionName = success.data.region.name;
          let subRegionName = success.data.subRegion.name;

          country !== '' &&
            this.userCountryForm.get('country').setValue(country);
          regionName !== '' &&
            this.userRegionsForm.get('region').setValue(regionName);
          subRegionName !== '' &&
            this.userRegionsForm.get('subRegion').setValue(subRegionName);

          if (country !== '') {
            this.selectedCountry = country;
            this.regionsArr = this.tempRegionsArr;
            let regions = this.regionsArr.filter(
              (region) => region.country === country
            );
            this.regionsArr = regions;

            this.designationArr = this.designationArrTemp;
            let designations = this.designationArr.filter(
              (designation) => designation.country === country
            );
            this.designationArr = designations;

            this.rolesArr = this.rolesArrTemp;
            let roles = this.rolesArr.filter(
              (role) => role.country === country
            );
            this.rolesArr = roles;

            this.positionArr = this.positionArrTemp;
            let positions = this.positionArr.filter(
              (role) => role.country === country
            );
            this.positionArr = positions;

            if (this.countriesSelected.includes(country)) {
              this.designationArr.unshift({ name: 'Other Designation' });
              this.rolesArr.unshift({ name: 'Other Role' });
              this.deparmentArr.unshift({ name: 'Other Department' });
              this.positionArr.unshift({ name: 'Other Position' });
              this.subspecialtyArr.unshift({ name: 'Other Subspecialty' });
            }
          }
        } else {
          // this.showErrorService.popToast('error', success.message);
          if (success.message === 'Password needs to be changed.') {
            setTimeout(() => {
              this.router.navigate([URLConstants.SETTINGS]);
            }, 2500);
          }
        }
      });
  }

  /****************************************************************************
     @Purpose     : set profile data to onboarding
     @Parameter   : $event
     @Return      : NA
  /****************************************************************************/
  async setUserDefaultDataUsingDomainAviation() {
    let user = this.localStorageService.getToken('currentUser');
    let data = await JSON.parse(user);
    let domainEmail = data.emailId;

    let signUpDomain = _.last(domainEmail.split('@')).toLowerCase().trim();

    this.commonService
      .callApi(
        this.callAPIConstants.userGetDomainData,
        {
          domainName: signUpDomain,
        },
        'post',
        false,
        false
      )
      .then((success) => {
        if (success.status === 1) {
          let country = success.data?.aviationCountry;
          let regionName = success.data?.region?.name;
          let type = success.data.type;
          let airline = success.data?.airline?.name;
          let placeOfService = success.data?.placeOfService?.name;

          country !== '' &&
            this.userCountryForm.get('country').setValue(country);
          type !== '' && this.userTypeForm.get('type').setValue(type);
          regionName !== '' &&
            this.userRegionsForm.get('region').setValue(regionName);
          airline !== '' &&
            this.userAirlineForm.get('airline').setValue(airline);
          placeOfService !== '' &&
            this.userPlaceOfServiceForm
              .get('placeOfService')
              .setValue(placeOfService);

          if (country !== '') {
            this.regionsArr = this.tempRegionsArr;
            let regions = this.regionsArr.filter(
              (region) => region.country === country
            );
            this.regionsArr = regions;

            this.airlinesArr = this.tempAirlinesArr;
            let airlines = this.airlinesArr.filter(
              (region) => region.country === country
            );
            this.airlinesArr = airlines;

            this.placeOfServiceArr = this.tempPlaceOfServiceArr;
            let services = this.placeOfServiceArr.filter(
              (region) => region.country === country
            );
            this.placeOfServiceArr = services;
          }
        } else {
          // this.showErrorService.popToast('error', success.message);
          if (success.message === 'Password needs to be changed.') {
            setTimeout(() => {
              this.router.navigate([URLConstants.SETTINGS]);
            }, 2500);
          }
        }
      });
  }

  closeModal(flag: boolean) {
    this.localStorageService.setToken(
      'dontShowOnboarding',
      JSON.stringify(true)
    );
    this.closeModalService.sendCloseEvent(flag);
  }
  onLanguageSelection(event) {
    const selectedValue = event.value;

    const data = {
      preferredLanguage: selectedValue,
    };

    this.spinnerService.show();

    this.commonService
      .callApi(
        this.callAPIConstants.updateUserPersonalData,
        data,
        'post',
        false,
        false
      )
      .then((success) => {
        if (success.status === 1) {
          // console.log(`language changed`, selectedValue);
          // this.localStorageService.setToken('language', selectedValue);
          // this.translate.use(selectedValue);
          this.globalLanguageService.sendGlobalLanguage(selectedValue);
          this.spinnerService.hide();
          this.showErrorService.popToast('success', success.message);
        } else {
          this.showErrorService.popToast('error', success.message);
        }
      })
      .catch((error) => {
        this.spinnerService.hide();
        console.error(error);
        this.showErrorService.popToast(
          'error',
          'Failed to update language preference.'
        );
      });
  }

  getPrivacyPolicy() {
    let currentLanguage = this.localStorageService.getToken('language');
    let data = {
      pageId: 'privacy_policy',
      ip: this.ip,
      language: currentLanguage,
    };
    this.commonService
      .callApi(this.callAPIConstants.cmsPages, data, 'post', true)
      .then((success) => {
        if (success.status === 1) {
          this.privacyPlicy['content'] = success.data.description;
        }
      });
  }

  getTermsAndConditions() {
    let currentLanguage = this.localStorageService.getToken('language');
    let data = {
      pageId: 'terms_and_condition',
      ip: this.ip,
      language: currentLanguage,
    };
    this.commonService
      .callApi(this.callAPIConstants.cmsPages, data, 'post', true)
      .then((success) => {
        if (success.status === 1) {
          this.termsAndConditions = success.data.description;
        }
      });
  }

  openTermsAndConditions(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: 'onboarding-modal modal-dialog-centered',
      ignoreBackdropClick: false,
      keyboard: false,
    });
  }
  openPrivacyPolicy(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: 'onboarding-modal modal-dialog-centered',
      ignoreBackdropClick: false,
      keyboard: false,
    });
  }
  deptSelect(value) {
    this.userDepartmentForm.controls['otherDepartment'].setValue('');
    this.userDepartmentForm.controls['otherSubspecialties'].setValue('');
    this.subspecialtyArr.unshift({ name: 'Other Subspecialty' });
    const deptName = value.map((value) => value.name);
    const matchingDepartments = this.deparmentArrTemp.filter((department) =>
      deptName.includes(department.name)
    );
    const subspecialArr = matchingDepartments
      .map((department) => department.subspecialties)
      .reduce(
        (accumulator, currentValue) => accumulator.concat(currentValue),
        []
      );
    this.subspecialtyArr = subspecialArr;
  }
  subClear(value) {
    this.userDepartmentForm.controls['otherSubspecialties'].setValue('');
    if (value.label === 'Other Subspecialty') {
      this.otherSubspecialtySelected = false;
    }
  }
  subspecialtyAdd(value) {
    this.userDepartmentForm.controls['otherSubspecialties'].setValue('');
    if (value.name === 'Other Subspecialty') {
      // Handle the case when 'Other Subspecialty' is selected
      this.otherSubspecialtySelected = true;
      this.userDepartmentForm.controls['subspecialties'].setValue([
        'Other Subspecialty',
      ]);
    } else {
      const subspecialtyArray = this.userDepartmentForm.value.subspecialties;
      const filteredArray = subspecialtyArray.filter(
        (item) => item !== 'Other Subspecialty'
      );
      this.userDepartmentForm.controls['subspecialties'].setValue(
        filteredArray
      );
      this.otherSubspecialtySelected = false;
    }
  }
  subspecSelect(value) {
    this.userDepartmentForm.controls['otherSubspecialty'].setValue('');
  }
}
