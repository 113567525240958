import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

// Constants
import { URLConstants } from '../../constants/routerLink-constants';

// Service
import { LocalStorageService } from '../service/local-storage.service';

/****************************************************************************
@PURPOSE      : Dont allow public pages to get accessed. (After Login)
@PARAMETERS   : N/A
@RETURN       : <boolean>
/****************************************************************************/
@Injectable()
export class CanLoginActivate implements CanActivate {
  URLConstants = URLConstants;
  constructor(
    public localStorageService: LocalStorageService,
    public router: Router
  ) {}
  canActivate() {
    if (!this.localStorageService.getToken('accessToken')) {
      return true;
    }
    this.router.navigate([this.URLConstants.FEED]);
    return false;
  }
}
/****************************************************************************/

/****************************************************************************
@PURPOSE      : Dont allow authorized pages to get accessed.  (Before Login)
@PARAMETERS   : N/A
@RETURN       : <boolean>
/****************************************************************************/
// tslint:disable-next-line: max-classes-per-file
@Injectable()
export class CanAuthActivate implements CanActivate {
  URLConstants = URLConstants;
  constructor(
    public localStorageService: LocalStorageService,
    public router: Router
  ) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.localStorageService.getToken('accessToken')) {
      return true;
    }
    this.router.navigate([this.URLConstants.HOMEPAGE], {
      queryParams: { redirectURL: state.url },
    });
    return false;
  }
}
/****************************************************************************/

/****************************************************************************
@PURPOSE      : Dont allow healthcare users to access the route
@PARAMETERS   : N/A
@RETURN       : <boolean>
/****************************************************************************/
// tslint:disable-next-line: max-classes-per-file
@Injectable()
export class AviationRouteGuard implements CanActivate {
  URLConstants = URLConstants;
  constructor(
    public localStorageService: LocalStorageService,
    public router: Router
  ) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let logedUserData = JSON.parse(
      this.localStorageService.getToken('currentUser')
    );
    if (logedUserData.industry === 'aviation') {
      return true;
    }
    this.router.navigate([this.URLConstants.HOMEPAGE]);
    return false;
  }
}
/****************************************************************************/

/****************************************************************************
@PURPOSE      : Dont allow aviation users to access the route
@PARAMETERS   : N/A
@RETURN       : <boolean>
/****************************************************************************/
// tslint:disable-next-line: max-classes-per-file
@Injectable()
export class HealthcareRouteGuard implements CanActivate {
  URLConstants = URLConstants;
  constructor(
    public localStorageService: LocalStorageService,
    public router: Router
  ) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let logedUserData = JSON.parse(
      this.localStorageService.getToken('currentUser')
    );
    if (logedUserData.industry === 'healthcare') {
      return true;
    }
    this.router.navigate([this.URLConstants.HOMEPAGE]);
    return false;
  }
}
/****************************************************************************/

/****************************************************************************
@PURPOSE      : Cheo specific routes
@PARAMETERS   : N/A
@RETURN       : <boolean>
/****************************************************************************/
// tslint:disable-next-line: max-classes-per-file
@Injectable()
export class CheoRouteGuard implements CanActivate {
  URLConstants = URLConstants;
  constructor(
    public localStorageService: LocalStorageService,
    public router: Router
  ) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let logedUserData = JSON.parse(
      this.localStorageService.getToken('currentUser')
    );
    if (logedUserData.institution === 'Cheo') {
      return true;
    }
    this.router.navigate([this.URLConstants.HOMEPAGE]);
    return false;
  }
}
/****************************************************************************/
@Injectable()
export class BruyereRouteGuard implements CanActivate {
  URLConstants = URLConstants;
  constructor(
    public localStorageService: LocalStorageService,
    public router: Router
  ) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let logedUserData = JSON.parse(
      this.localStorageService.getToken('currentUser')
    );
    if (logedUserData.institution === 'Bruyere') {
      return true;
    }
    this.router.navigate([this.URLConstants.HOMEPAGE]);
    return false;
  }
}

@Injectable()
export class PerleyRouteGuard implements CanActivate {
  URLConstants = URLConstants;
  constructor(
    public localStorageService: LocalStorageService,
    public router: Router
  ) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let logedUserData = JSON.parse(
      this.localStorageService.getToken('currentUser')
    );
    if (logedUserData.institution === 'Perley') {
      return true;
    }
    this.router.navigate([this.URLConstants.HOMEPAGE]);
    return false;
  }
}
