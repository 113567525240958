export const callAPIConstants = {
  Registration: 'users/register',
  Login: 'users/login',
  ResendOTP: 'users/resendOtp',
  SubmitOTP: '/users/validateOtp',
  SigIn: 'users/login',
  OneTimeSigIn: 'users/oneTimelogin',
  ForgetEmail: 'users/forgotPassword',
  SuggestDomain: 'users/suggestDomain',
  Logout: 'users/logout',
  UserNotification: 'users/pushNotificationToggle',
  SavedSearchedNotification: 'users/relatedContentNotificationToggle',
  UserEmailNotification: 'users/emailNotificationToggle',
  UserMfaToggle: 'users/mfaToggle',
  DeleteUserAccount: 'users/deactivateUser',
  ResetPassword: 'users/resetPassword',
  GetUserProfile: 'users/profile',
  profileListingDetails: 'users/profileListingDetails',
  changePassword: 'users/changePassword',
  updateUserPersonalData: 'users/updateUserProfile',
  hideIncidentForUser: 'users/updateHiddenIncidents',
  undoHideIncidentForUser: 'users/undoHiddenIncidents',
  userFileUpload: 'users/fileUpload',
  userEditFilter: 'users/filters',
  userGetFilter: 'users/filters',
  userGetOnboardingData: 'users/onboarding',
  userEditOnboardingData: 'users/updateOnboarding',
  userDontShowOnboardingData: 'users/dontShowOnboarding',
  userVerifyAccount: 'users/verifyUserToken',
  userSignInOnboarding: 'users/onboardingLogin',
  userGetDomainData: 'domain/getDomainByName',
  userSetPassword: 'users/setPassword',
  userSignupOnboarding: 'users/onboardingSignup',
  getIncidentData: 'incident/getMetaData',
  incidentFileUpload: 'incident/fileUpload',
  postIncident: 'incident/postIncident',
  deleteMedia: 'incident/deleteUploadedFile',
  getMyContributions: 'incident/myContributionListing',
  getMyContributionsSolutions: 'solution/myContributionListing',
  getIncidentById: 'incident/getIncidentById',
  updatedIncident: 'incident/editMyIncident',
  updatedSolution: 'solution/editMySolution',
  deleteIncident: 'incident/requestForDeleteIncident',
  deleteSolution: 'solution/requestForDeleteSolution',
  getIncidentFeedList: 'incident/incidentSolutionListing',
  getIncidentFeedListPublic: 'incident/incidentListingPublic',
  getFavouriteList: 'incident/favoriteIncidentListing',
  getIncidentDetailById: 'incident/getIncidentByIdWithSolutions',
  giveSolution: 'solution/giveSolution',
  getSolutionDetails: 'solution/getSolutionDetails',
  getSolutionById: 'solution/getSolutionById',
  likeAndIncident: 'incidentSolution/likeDislikeIncidentSolution',
  addFavourite: 'incident/favoriteUnfavoriteIncident',
  addComment: 'incident/addComment',
  addReply: 'solution/addReply',
  getReplyList: 'solution/getReplyList',
  editReply: 'solution/editReply',
  deleteReply: 'solution/deleteReply',
  deleteComment: '/incident/deleteComment',
  editComment: '/incident/editComment',
  commentList: 'incident/getCommentList',
  ReportIncident: 'report/reportIncidentSolution',
  RemoveReportIncident: 'report/removeReportIncidentSolution',
  getNotificationList: 'userNotificationListing',
  getNotificationListCount: 'userGetNotificationCount',
  readNotification: 'userReadNotifications',
  readNotificationOnClick: 'userReadNotificationSingular',
  reachUS: 'users/reachUs',
  aboutUS: 'users/aboutUS',
  privacyPolicy: 'privacypolicy',
  termsAndConditions: 'termsAndConditions',
  cmsPages: 'users/cmsPages',
  home: 'home',
  faq: 'getFAQ',
  generateFakeuser: 'users/newFakeUsername',
  refreshPfp: 'users/refreshPfp',
  DomainList: '/users/activeDomainList',
  getInfographicListing: '/infographic/infographicListing',
  updateQrVisits: '/qrCampaignManagement/updateViewsSignups',
  updatePerPageVisits: '/qrCampaignManagement/updateViewsPerPage',
  news: 'news/newsList',
  essentialTools: '/medicalCalculator/medicalCalculatorList',
  addFavouriteTool: '/medicalCalculator/favoriteUnfavoriteTool',
  favouriteToolListing: '/medicalCalculator/favoriteToolListing',
  searchTool: '/medicalCalculator/search',
  searchUsername: '/incident/checkUsernameAvailability',
  searchFlightInfo: '/flightInfo/search',
  saveSearches: '/users/saveSearchTerms',
  getAviationPfp: '/flightInfo/getPfp',
  getTopTenFlightInfo: '/flightInfo/getTopTen',
  searchFlightInfoFeed: '/incident/searchAir',
  blogs: '/blogs/blogsList',
  getBlogsById: '/blogs/getBlogById',
  airports: 'airport/airportListing',
  airportSearch: 'airport/search',
  searchSuggestion: 'incident/searchSuggestions',

  //cheo urls
  getQuestionData: 'questions/questionList',
  postIncidentCheo: 'incident/postCheoIncident',
  postIncidentBruyere: 'incident/postBruyereIncident',
  postIncidentPerley: 'incident/postPerleyIncident',
  createIncidentCache: 'incident/cheoIncidentCache',
  autofillIncident: 'incident/autofillQuestions',
  updateIncidentCache: 'incident/updateCheoIncidentCache',
  getIncidentCacheById: 'incident/getCheoCacheById',
  getCheoIncidentById: 'incident/getCheoIncidentById',
  updateCheoIncident: 'incident/updateCheoIncident',
  getIncidentCacheUser: 'incident/userCheoIncidentCacheList',
  addCheoComment: 'incident/addCheoComment',
  completeActionItem: 'incident/completeActionItem',
  commentCheoList: '/incident/getCheoCommentList',
  editCheoComment: '/incident/editCheoComment',
  deleteCheoComment: '/incident/deleteCheoComment',
  getCheoIncidentDetailById: 'incident/getCheoIncidentByIdWithSolutions',
  getCheoSubmissions: 'incident/cheoSubmissions',
  getViewers: 'cheouser/viewerListing',
  completeCheoReport: 'incident/completeReport',
  getMySubmissionsCountCheo: 'incident/mySubmissionsCountCheo',
  getMyInvestigationsCountCheo: 'incident/myInvestigationsCountCheo',
  getCheoInvestigations: 'incident/cheoInvestigations',
  getCheoViewerInvestigations: 'incident/cheoViewerInvestigations',
  deleteDraft: 'incident/deleteDraft',
  cheoIncidentFileUpload: 'incident/cheofileUpload',
  cheoDeleteMedia: 'incident/cheodeleteUploadedFile',
  cheoAddNewAssignees: '/incident/addNewAssignees',
};
